import { render, staticRenderFns } from "./SingleDele.vue?vue&type=template&id=808dc536&scoped=true&"
import script from "./SingleDele.vue?vue&type=script&lang=js&"
export * from "./SingleDele.vue?vue&type=script&lang=js&"
import style0 from "./SingleDele.vue?vue&type=style&index=0&id=808dc536&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "808dc536",
  null
  
)

export default component.exports