<template>
  <div>
    <h2>Register</h2>
    <form @submit.prevent="register">
      <label for="email">Email:</label>
      <input type="email" id="email" v-model="email" required>
      <br>
      <label for="password">Password:</label>
      <input type="password" id="password" v-model="password" required>
      <br>
      <button type="submit">Register</button>
    </form>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import 'firebase/auth';

export default {
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    register() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(() => {
          // User registered successfully
          // Redirect to login page or perform other actions
        })
        .catch(error => {
          console.log(error);
        });
    },
  },
};
</script>
