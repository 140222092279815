<template>
  <div>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Delegationsförteckning" active>
          <div v-if="loading" role="alert" aria-live="assertive">Laddar...</div>
          <div v-else-if="publicDelegationBySlug" tabindex="0">
            <div>
              <h1 tabindex="0">{{ publicDelegationBySlug.title }}</h1>
              <div>
                <button
                  class="btn btn-primary"
                  @click="showSideMenu = !showSideMenu"
                  style="margin: 15px 15px 15px 0px"
                >
                  <i class="bi bi-list-columns" style="margin-right: 10px"></i
                  >Innehållsförteckning
                </button>
                <b-button
                  class="btn btn-primary"
                  @click="handlePdf()"
                  style="margin-right: 15px"
                >
                  <i class="bi bi-file-pdf-fill" style="margin-right: 10px"></i
                  >PDF</b-button
                >
              </div>
            </div>
            <div
              v-for="(delegations, avsnittIndex) in publicDelegationBySlug.rows"
              :key="delegations.key"
            >
              <h2 tabindex="0" v-if="delegations.avsnitt">
                {{ avsnittIndex + 1 + "." }} {{ delegations.avsnitt }}
              </h2>
              <div
                v-for="(allTables, allTableIndex) in delegations.allTables"
                :key="allTables.key"
              >
                <h3 tabindex="0" v-if="allTables.id">
                  {{ avsnittIndex + 1 + "." }}{{ allTableIndex + 1 + ". " }}
                  {{ allTables.id }}
                </h3>
                <table tabindex="0" class="table table-striped table-bordered">
                  <caption v-if="allTables.id">
                    Tabell
                    {{
                      allTables.id
                    }}
                  </caption>
                  <caption v-else-if="delegations.avsnitt">
                    Tabell
                    {{
                      delegations.avsnitt
                    }}
                  </caption>
                  <thead style="background: #00627d; color: white">
                    <tr>
                      <th tabindex="0" scope="col" style="width: 5%">Nr</th>
                      <th tabindex="0" scope="col" style="width: 20%">
                        Ärendetyp
                      </th>
                      <th tabindex="0" scope="col" style="width: 10%">
                        Delegat
                      </th>
                      <th tabindex="0" scope="col" style="width: 6%">
                        Vidaredelegation
                      </th>
                      <th tabindex="0" scope="col" style="width: 6%">Lagrum</th>
                      <th tabindex="0" scope="col" style="width: 19%">
                        Anmärkning
                      </th>
                      <th tabindex="0" scope="col" style="width: 14%">
                        Ärenden som vidaredelegeras
                      </th>
                      <th tabindex="0" scope="col" style="width: 10%">
                        Vidaredelegat
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, rowIndex) in allTables.table"
                      :key="row.key"
                    >
                      <td tabindex="0">
                        {{ avsnittIndex + 1 + "." }}{{ allTableIndex + 1 + "."
                        }}{{ rowIndex + 1 }}
                      </td>
                      <td tabindex="0">{{ row.arendetyp }}</td>
                      <td tabindex="0">
                        <span
                          v-b-modal.modal-5
                          @click="matchDelegat(row.delegat)"
                          class="clickable"
                        >
                          {{ row.delegat }}
                        </span>
                      </td>
                      <td tabindex="0">{{ row.vd }}</td>
                      <td tabindex="0">{{ row.lagrum }}</td>
                      <td tabindex="0">{{ row.anmarkning }}</td>
                      <td tabindex="0">
                        <span
                          tabindex="0"
                          v-for="arende in row.arendenVidare"
                          :key="arende.key"
                          :class="{ 'arendenArray': row.arendenVidare.length > 1 }"
                        >
                          {{ arende }}
                        </span>
                      </td>
                      <td tabindex="0">
                        <span
                          tabindex="0"
                          v-b-modal.modal-5
                          v-for="delegat in row.vidaredelegat"
                          :key="delegat.key"
                          @click="matchDelegat(delegat)"
                          class="clickable"
                          :class="{ 'arendenArray': row.vidaredelegat.length > 1 }"
                        >
                          {{ delegat }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Om delegationsordning">
          <div
            style="text-align: left"
            class="om-dele"
            v-if="publicDelegationBySlug && publicDelegationBySlug.about"
            v-html="publicDelegationBySlug.about"
          ></div>
        </b-tab>
        <b-tab title="Bilaga">
          <div
            style="text-align: left"
            class="om-dele"
            v-if="publicDelegationBySlug && publicDelegationBySlug.bilaga"
            v-html="publicDelegationBySlug.bilaga"
          ></div>
          <div v-else>
            Delegationsordningen har ingen bilaga.
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal
      id="modal-5"
      size="xl"
      :title="this.currentDelegat"
      ref="showDelegat-modal"
      @hidden="onHidden"
      hide-footer
      hide-close
    >
      <div v-if="this.matchDelegations != ''">
        <h6>{{ this.currentDelegat }} är delegat i följande ärenden:</h6>
        <table class="table table-striped table-bordered">
          <thead style="background: #ff8204; color: white">
            <tr>
              <th>Delegationsordning</th>
              <th>Avsnitt</th>
              <th>Tabell</th>
              <th>Nr</th>
              <th>Typ av ärenden</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="matched in this.matchDelegations" :key="matched.key">
              <td>{{ matched.delegationsordning }}</td>
              <td @click="closeModal()">
                <router-link :to="'/publik/' + matched.slug">{{
                  matched.avsnitt
                }}</router-link>
              </td>
              <td>{{ matched.tabell }}</td>
              <td>{{ matched.nr }}</td>
              <td>{{ matched.arendetyp }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="this.matchVd != ''">
        <h6>{{ this.currentDelegat }} är vidaredelegat i följande ärenden:</h6>
        <table class="table table-striped table-bordered">
          <thead style="background: #ff8204; color: white">
            <tr>
              <th>Delegationsordning</th>
              <th>Avsnitt</th>
              <th>Tabell</th>
              <th>Nr</th>
              <th>Typ av ärenden</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="matched in this.matchVd" :key="matched.key">
              <td>{{ matched.delegationsordning }}</td>
              <td>
                <router-link :to="'/publik/' + matched.slug">{{
                  matched.avsnitt
                }}</router-link>
              </td>
              <td>{{ matched.tabell }}</td>
              <td>{{ matched.nr }}</td>
              <td>{{ matched.arendetyp }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="this.matchVd == '' && this.matchDelegations == ''">
        <h6>{{ this.currentDelegat }} får inte fatta delegationsbesut</h6>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { generatePdf } from "./pdfgenerator.js";

export default {
  data() {
    return {
      loading: false,
      parsedData: [],
      matchDelegations: [],
      matchVd: [],
      currentDelegat: [],
      slug: ''
    };
  },
  computed: {
    publicDelegations() {
      return this.$store.state.publicDelegations;
    },
    publicDelegationBySlug() {
      return this.$store.state.publicDelegationBySlug;
    },
  },
  methods: {
    handlePdf() {
      // Först, spara nuvarande data om det behövs
      const originalData = JSON.parse(JSON.stringify(this.publicDelegationBySlug));

      // Generera PDF
      this.parseJsonData(this.publicDelegationBySlug);
      generatePdf(this.publicDelegationBySlug.about, this.parsedData);

      // Återställ data (antingen med sparad kopia eller genom att ladda om)
      this.publicDelegationBySlug = originalData;
      // Eller ladda om data från källan
      this.reloadData();
    },
    matchDelegat(delegatTitel) {
      this.currentDelegat = delegatTitel;

      const delegations = this.$store.state.publicDelegations;

      // Töm eventuella tidigare resultat
      this.matchVd = [];
      this.matchDelegations = [];

      delegations.forEach((delegation) => {
        delegation.rows?.forEach((avsnitt) => {
          avsnitt.allTables?.forEach((allTable) => {
            allTable.table?.forEach((table) => {
              if (table.vidaredelegat?.includes(delegatTitel)) {
                const delegationMatch = {
                  id: delegation.id,
                  delegationsordning: delegation.title,
                  avsnitt: avsnitt.avsnitt,
                  tabell: allTable.id,
                  nr: table.nr,
                  arendetyp: table?.arendetyp,
                  slug: delegation.slug
                };
                this.matchVd.push(delegationMatch);
              }
              if (table.delegat === delegatTitel) {
                const delegationMatch = {
                  id: delegation.id,
                  delegationsordning: delegation.title,
                  avsnitt: avsnitt.avsnitt,
                  tabell: allTable.id,
                  nr: table.nr,
                  arendetyp: table.arendetyp,
                  slug: delegation.slug
                };
                this.matchDelegations.push(delegationMatch);
              }
            });
          });
        });
      });
      this.getData();
      this.reloadData();
    },
    parseJsonData(jsonData) {
      try {
        this.parsedData.push(jsonData);
      } catch (e) {
        console.error("Error parsing JSON data", e);
        this.parsedData = null;
      }
    },
    getData() {
      if (this.$store.state.publicDelegations.length === 0) {
        this.loading = true;
        this.$store.dispatch("fetchPublicDelegations").then(() => {
          this.loading = false;
        });
      }
    },
    reloadData() {
      this.loading = true;
      this.$store.dispatch("fetchPublicDelegations").then(() => {
        this.loading = false;
      });
    },
    loadDelegationBySlug(slug) {
    // Anropa Vuex action med slug som parameter
      this.$store.dispatch('fetchDelegationBySlug', slug)
        .then(() => {
          console.log('Delegation loaded successfully');
        })
        .catch(error => {
          console.error('Error loading delegation:', error);
        });
    },
  },
  watch: {
    '$route.params.slug'(newSlug) {
      this.loadDelegationBySlug(newSlug);
    }
  },
  mounted() {
    this.slug = this.$route.params.slug;
    this.loadDelegationBySlug(this.slug);
    this.getData();
  },
};
</script>

<style scoped>
caption {
  color: #050505;
}

h1 {
  font-size: 1.8rem!important;
}

h2 {
  font-size: 1.6rem!important;
}

td {
  font-size: 0.85rem;
}

.clickable {
  text-decoration: underline;
}
.arendenArray {
  display: block;
  padding: 10px 0px;
  border-bottom: 1px solid #ccc;
  min-height: 130px;
}

.arendenArray:first-child { 
  padding: 0px 0px 10px 0px;
}

.arendenArray:last-child { 
  border-bottom: 0px;
}

/deep/ .om-dele table,
.om-dele td {
  border: 1px solid #e3e3e3;
}

/deep/ .om-dele h2 {
  font-size: 1.6rem!important;
}

/deep/ .om-dele td {
  padding: 5px;
  border-width: 1px!important;
}

/deep/ .om-dele p {
  margin-bottom: 7.5px;
}


</style>
<!---
<template>
    <div>
      <ul>
        <li v-for="item in dataList" :key="item.id" @click="loadEntry(item.id)">
          {{ item.title }}
        </li>
      </ul>
      <div v-if="selectedEntry">
        {{ selectedEntry }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    computed: {
      dataList() {
        return this.$store.state.data;
      },
      selectedEntry() {
        return this.$store.state.selectedEntry;
      }
    },
    created() {
      this.$store.dispatch('fetchData');
    },
    methods: {
      loadEntry(entryId) {
        this.$store.dispatch('loadEntry', entryId);
      },
    }
  };
  </script>  
-->
