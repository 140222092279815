import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { db } from '../firebase.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    data: [],
    selectedEntry: null,
    delegations: [],
    delegationsBySlug: [],
    publicDelegations: [],
    publicDelegationBySlug: {},
    isAdmin: false,
  },
  mutations: {
    setDelegations(state, payload) {
      state.delegations = payload;
    },
    setPublicDelegations(state, delegations) {
      state.publicDelegations = delegations;
    },
    setPublicDelegationsBySlug(state, delegationsBySlug) {
      state.publicDelegationBySlug = delegationsBySlug;
    },
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_SELECTED_ENTRY(state, payload) {
      state.selectedEntry = payload;
    },
    setAdminStatus(state, status) {
      state.isAdmin = status;
    }
  },
  actions: {
    fetchDelegations({ commit }) {
      const delegationsRef = db.collection("PendingDelegationsordning");
  
      delegationsRef.get().then((querySnapshot) => {
        let delegations = [];
  
        querySnapshot.forEach((doc) => {
          // Här samlar vi all data från varje dokument
          let docData = doc.data();
  
          // Här skapar vi ett objekt för varje dokument
          // och inkluderar dess ID och data
          let delegation = {
            id: doc.id,
            ...docData
          };
  
          // Lägg till objektet i arrayen
          delegations.push(delegation);
        });
  
        // Commit datan till store
        commit('setDelegations', delegations);
        console.log(delegations);
      }).catch(error => {
        console.error('Error fetching delegations:', error);
      });
    },
    fetchPublicDelegations({ commit }) {
      axios.get('https://us-central1-delegationsportalen.cloudfunctions.net/getData')
      .then(result => {
        commit('setPublicDelegations', result.data);
        console.log('Fetched public delegations:', result.data);
      }).catch(error => {
        console.error('Error fetching public delegations:', error);
      });
    },
    fetchData({ commit }) {
      axios.get('https://us-central1-delegationsportalen.cloudfunctions.net/getData')
        .then(response => {
          commit('SET_DATA', response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    loadEntry({ commit }, entryId) {
      axios.get(`https://us-central1-delegationsportalen.cloudfunctions.net/getEntry?id=${entryId}`)
        .then(response => {
          commit('SET_SELECTED_ENTRY', response.data);
        })
        .catch(error => {
          console.error('Error fetching entry:', error);
        });
    },
    fetchDelegationBySlug({ commit }, slug) {
      // Ange URL till din Firebase Function som hanterar hämtning baserat på slug
      const url = `https://us-central1-delegationsportalen.cloudfunctions.net/getDelegationBySlug?slug=${slug}`;
  
      // Använd axios för att anropa din Firebase Function
      axios.get(url)
        .then(response => {
          const data = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
          commit('setPublicDelegationsBySlug', data);
        })
        .catch(error => {
          console.error('Error fetching delegation by slug:', error);
        });
    },  
    updateAdminStatus({ commit }, status) {
      commit('setAdminStatus', status);
    }
  },
  getters: {
    isAdmin: state => state.isAdmin,
  }
});
