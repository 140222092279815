import { render, staticRenderFns } from "./OmDele.vue?vue&type=template&id=125932d4&scoped=true&"
import script from "./OmDele.vue?vue&type=script&lang=js&"
export * from "./OmDele.vue?vue&type=script&lang=js&"
import style0 from "./OmDele.vue?vue&type=style&index=0&id=125932d4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "125932d4",
  null
  
)

export default component.exports