<template>
    <div>
      <b-navbar toggleable="lg" type="dark" variant="info">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Put other navbar content here -->
        </b-collapse>
      </b-navbar>
  
      <b-sidebar
        v-model="sidebarShow"
        :visible="isSidebarOpen"
        shadow
      >
        <!-- Sidebar content here -->
      </b-sidebar>
  
      <b-container fluid>
        <!-- Main content goes here -->
        <slot></slot>
      </b-container>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        sidebarShow: true
      };
    },
    computed: {
      isSidebarOpen() {
        return window.innerWidth > 992 ? true : this.sidebarShow;
      }
    },
    mounted() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    methods: {
      handleResize() {
        this.sidebarShow = window.innerWidth > 992;
      }
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    }
  };
  </script>
  
  <style>
  /* Add your custom CSS here */
  </style>
  