<template>
    <div style="text-align:left;padding: 30px;">
        <div class="card">
            <h4>Anmäl ändring</h4>
            <b-form @submit.prevent="submitForm">
                <label class="sr-only" for="title">Delegationsordning</label>
                <b-form-select class="form-select" id="title" v-model="changes.title">
                    <option value="">Gäller Delegationsordning...</option>
                    <option v-for="order in delegationsOptions" :model="order.title" :key="order.value">{{ order.text }}</option>
                </b-form-select>
                <br>
                <label for="avsnitt">Avsnitt</label>
                <b-form-input type="text" id="avsnitt" v-model="changes.avsnitt" />
                <br>
                <label class="sr-only" for="tabell">Tabell:</label>
                <b-form-input type="text" id="tabell" v-model="changes.tabell" />
                <br>
                <label class="sr-only" for="message">Meddelande:</label>
                <b-form-textarea type="text" id="message" v-model="changes.message" />
                <br>
                <b-button class="btn btn-primary" type="submit">Anmäl ändring</b-button>
            </b-form>
            <div v-if="submitted">
                <p>Din ändringsanmälan har registrerats</p>
            </div>
        </div>
        <div v-if="isAdmin" class="card mt-4">
            <h4>Rapporterade ändringar</h4>
            <table class="table table-striped table-bordered" :key="report.id">
                <thead>
                    <tr>
                    <th>Delegationsordning</th>
                    <th>Avsnitt</th>
                    <th>Tabell</th>
                    <th>Meddelande</th>
                    <th>Markera</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="report in reportedChanges" :key="report.collectionId">
                        <td>{{ report.title }}</td>
                        <td>{{ report.avsnitt }}</td>
                        <td>{{ report.tabell }}</td>
                        <td>{{ report.message }}</td>
                        <td>
                            <button class="btn btn-primary" @click="deleteReport(report.collectionId)">
                            Klar
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
          <div v-if="isAdmin" class="card mt-4" style="display:none;">
            <h4>Senaste ändringar</h4>
            <table class="table table-striped table-bordered" :key="report.id">
              <thead>
                    <tr>
                    <th>Id</th>
                    <th>Delegationsordning</th>
                    <th>Tid</th>
                    <th>Återställ</th>
                    </tr>
                </thead>
                <tbody>
                  <tr v-for="doc in versionedDocs" :key="doc.id">
                    <td>{{ doc.id }}</td>
                    <td>{{ doc.title }}</td>
                    <td>{{ doc.timestamp.toLocaleString() }}</td>
                    <td>
                      <button class="btn btn-primary" @click="revertDoc(doc.id)">
                        Återställ ändring
                      </button>
                    </td>
                  </tr>
                </tbody>
            </table>
          </div>
    </div>
  </template>
<script>
import firebase from "firebase/compat/app";
import 'firebase/compat/functions';
import "firebase/compat/firestore";
import "firebase/compat/auth";
import adminMixin from '../store/adminMixin';
import { db } from '../firebase.js';

export default {
  name: "DeleReported",
  mixins: [adminMixin],
  data() {
    return {
      changes: {
        avsnitt: "",
        message: "",
        tabell: "",
        title: ""
      },
      report: [],
      delegationsOptions: [],
      reportedChanges: [],
      submitted: false,
      versionedDocs: [], // Array to hold the fetched documents
      adminUsers: []
    };
  },
  async created() {
    this.fetchVersionedDocs();
    const delegationsRef = db.collection("Delegationsordning");
    const delegationsSnapshot = await delegationsRef.get();
    this.delegationsOptions = delegationsSnapshot.docs.map((doc) => ({
      text: doc.data().title,
      value: doc.id,
    }));

    // Load reported changes
    const reportedRef = db.collection("Reported");
    const reportedSnapshot = await reportedRef.get();
    this.reportedChanges = reportedSnapshot.docs.map((doc) => ({
      collectionId: doc.id,
      ...doc.data().changes
    }));
    console.log(this.reportedChanges);
  },
  methods: {
    // Fetch documents from "VersionDelegationsordning" collection
    fetchVersionedDocs() {
      db.collection("VersionDelegationsordning")
        .get()
        .then((querySnapshot) => {
          this.versionedDocs = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            // Convert Timestamp to JavaScript Date
            if (data.timestamp) {
              data.timestamp = data.timestamp.toDate();
            }
            data.id = doc.id; // Add the ID to the data object
            return data;
          });
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
        });
    },
    // Revert a document back to "Delegationsordning" collection
    revertDoc(id) {
      const docData = this.versionedDocs.find((doc) => doc.id === id);

      if (docData) {
        db.collection("Delegationsordning")
          .doc(id)
          .set(docData)
          .then(() => {
            console.log("Document reverted successfully!");
          })
          .catch((error) => {
            console.error("Error reverting document: ", error);
          });
      } else {
        console.log(`Document with ID "${id}" not found in versionedDocs`);
      }
    },
    async fetchUsers() {
        try {
            const response = await fetch("https://us-central1-delegationsportalen.cloudfunctions.net/listUsers", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.ok) {
                    this.adminUsers = await response.json();
                    this.isLoading = false;
            } else {
                throw new Error('Failed to fetch users');
            }
        } catch (error) {
            console.error(error);
        }
    },
    async submitForm() {
        // Add new document to "Reported" collection
        const reportedRef = db.collection("Reported");
        const timestamp = new Date().getTime();
        const newReport = {
            changes: {
            avsnitt: this.changes.avsnitt,
            message: this.changes.message,
            tabell: this.changes.tabell,
            title: this.changes.title,
            id: timestamp.toString()
            }
        };
        await reportedRef.add(newReport);

        // Add new change to reportedChanges array
        const newChange = {
            collectionId: '',
            ...newReport.changes
        };
        this.reportedChanges.push(newChange);

        await this.fetchUsers();

        const mailTasks = this.adminUsers.map(adminUser => {
            const mailData = {
                message: {
                    html: `Hej!<br>Här kommer en rapporterad ändring: <br><br><strong>Från delegationsportalen</strong><br>${this.changes.message}<br>${this.changes.title}<br>${this.changes.avsnitt}<br>${this.changes.tabell}`,
                    subject: `Rapporterad ändring i avsnitt ${this.changes.title}`,
                },
                to: adminUser.email
            };
            return db.collection("mail").add(mailData);
        });

        await Promise.all(mailTasks);

        // Reset form and set submitted flag
        this.changes.avsnitt = "";
        this.changes.message = "";
        this.changes.tabell = "";
        this.changes.title = "";
        this.submitted = true;
    },
    async deleteReport(reportId) {
      const confirmed = confirm("Är du säker på att du är klar med ändringen? Den kommer att raderas.");
      if (confirmed) {
        // Remove the document from the "Reported" collection
        const reportedRef = db.collection("Reported").doc(reportId);
        await reportedRef.delete();

        // Remove the document from the "reportedChanges" array
        const index = this.reportedChanges.findIndex((report) => report.collectionId === reportId);
        if (index > -1) {
            this.reportedChanges.splice(index, 1);
        }
      }
    }
  },
  mounted() {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.loggedInUserName = user.email;

        // Fetch the ID token and update admin status
        try {
          const idTokenResult = await user.getIdTokenResult();
          this.isAdminStatus = idTokenResult.claims.isAdmin === true;
        } catch (error) {
          console.error("Error fetching user token:", error);
          this.isAdminStatus = false;
        }
      } else {
        this.loggedInUserName = null;
        this.isAdminStatus = false; // Reset admin status on logout
      }
    });

  },
  computed: {
    isAdmin() {
      // Now depends on the data property 'isAdminStatus'
      return this.isAdminStatus;
    },
  }
};
</script>
