<template>
    <div style="padding:30px; text-align:left;">
        <div class="card">
            <h4>Skapa ny användare</h4>
            <b-form @submit.prevent="registerUser" v-if="users.length < 5">
                <div>
                    <label for="displayName">Namn:</label>
                    <b-form-input type="text" id="displayName" v-model="displayName" required />
                    <br />
                </div>
                <div>
                    <label for="email">E-post:</label>
                    <b-form-input type="email" id="email" v-model="email" required />
                    <br />
                </div>
                <div>
                    <label for="password">Lösenord:</label>
                    <b-form-input type="password" id="password" v-model="password" required />
                    <br />
                </div>
                <div>
                    <b-form-checkbox id="isAdmin" v-model="isAdmin" style="display:none;">
                        <span style="margin-left: 5px;">Har adminrättigheter</span>
                    </b-form-checkbox>
                    <br />
                </div>
                <div>
                    <button class="btn btn-primary" type="submit">Registrera</button>
                    <p v-if="error" class="error-message">{{ error }}</p>
                </div>
            </b-form>
            <div v-else>
                <p>Ni har nått det maximala antalet användare som kan skapas.</p>
            </div>
        </div>
         <!-- Add this modal for editing users -->
        <b-modal id="modal-1" ref="admin-modal" title="Redigera användare" class="edit-modal" hide-footer>
            <b-form @submit.prevent="submitEdit">
                <label for="editDisplayName">Namn:</label>
                <b-form-input type="text" id="editDisplayName" v-model="editDisplayName" required />
                <br />
                <label for="editEmail">E-post:</label>
                <b-form-input type="email" id="editEmail" v-model="editEmail" required />
                <br />
                <label for="editPassword">Lösenord (lämna tomt för att inte ändra):</label>
                <b-form-input type="password" id="editPassword" v-model="editPassword" />
                <br />
                <b-form-checkbox id="editIsAdmin" v-model="editIsAdmin">
                    <span style="margin-left: 5px;">Har adminrättigheter</span>
                </b-form-checkbox>
                <br />
                <button class="btn btn-primary" type="submit">Spara ändringar</button>
                <button class="btn btn-secondary" @click="showEditModal = false" style="margin-left: 10px;">Stäng</button>
            </b-form>
        </b-modal>

        <div class="card" v-if="users">
            <h4>Användare {{users.length}}/5</h4>
            <div v-if="isLoading">
                <b-spinner></b-spinner>
            </div>
            <table class="table table-striped table-bordered" v-else>
                <thead>
                    <tr>
                    <th>Namn</th>
                    <th>E-post</th>
                    <th>Roll</th>
                    <th style="width: 200px;">Hantera</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users" :key="user.uid">
                        <td>{{ user.displayName }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.isAdmin ? 'Administratör' : 'Redigerare' }}</td>
                        <td style="display: flex;justify-content: space-around;">
                            <button v-b-modal.modal-1 class="btn btn-primary" @click="openEditModal(user)">Redigera</button>
                            <button class="btn btn-danger" @click="deleteUser(user.uid)">Ta bort</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            displayName: "",
            email: "",
            password: "",
            error: null,
            users: [],
            isLoading: true,
            isAdmin: true,
            showEditModal: false,
            editDisplayName: "",
            editEmail: "",
            editPassword: "",
            editIsAdmin: false,
            editingUserId: null, // ID of the user being edited
        };
    },
    mounted() {
        this.fetchUsers();
    },
    methods: {
        async fetchUsers() {
            try {
                const response = await fetch(
                    "https://us-central1-delegationsportalen.cloudfunctions.net/listUsers", // Replace with the actual URL of your Firebase Function
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (response.ok) {
                    const users = await response.json();
                    this.users = users.filter(user => user.displayName !== "Max" && user.displayName !== "William Friman" && user.displayName !== "admin");
                    this.isLoading = false;
                }
            } catch (error) {
                console.error(error);
                this.isLoading = false;
            }
        },
        async registerUser() {
            try {
                // ... existing code in registerUser before the fetch call ...
                const response = await fetch(
                    "https://us-central1-delegationsportalen.cloudfunctions.net/createUser",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            displayName: this.displayName,
                            email: this.email,
                            password: this.password,
                            isAdmin: this.isAdmin, // Include the isAdmin flag
                        }),
                    }
                );

                if (response.ok) {
                    alert("User registered successfully");
                    this.clearForm();
                    this.fetchUsers();
                } else {
                    const data = await response.json();
                    if (data && data.error) {
                        this.error = data.error;
                    } else {
                        this.error = "Error registering user.";
                    }
                }
            } catch (error) {
                console.error(error);
                this.error = "Error registering user.";
            }
        },
        async editUser(uid, newDisplayName, newEmail, newPassword, newIsAdmin) {
            try {
                this.isLoading = true;
                const response = await fetch(
                "https://us-central1-delegationsportalen.cloudfunctions.net/editUser",
                {
                    method: "POST",
                    headers: {
                    "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                    uid,
                    displayName: newDisplayName,
                    email: newEmail,
                    password: newPassword,
                    isAdmin: newIsAdmin, // Include the isAdmin flag
                    }),
                }
                );

                if (response.ok) {
                alert("User updated successfully");
                this.fetchUsers();
                } else {
                const data = await response.json();
                if (data && data.error) {
                    this.error = data.error;
                } else {
                    this.error = "Error updating user.";
                }
                }
            } catch (error) {
                console.error(error);
                this.error = "Error updating user.";
            } finally {
                this.isLoading = false;
            }
        },
        clearForm() {
            this.displayName = "";
            this.email = "";
            this.password = "";
            this.error = null;
        },
        async deleteUser(uid) {
            try {
                this.isLoading = true;
            const response = await fetch(
                "https://us-central1-delegationsportalen.cloudfunctions.net/deleteUser", // Replace with the actual URL of your Firebase Function
                {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ uid }), // Pass the user's UID to delete
                }
            );

            if (response.ok) {
                alert("User deleted successfully");
                this.fetchUsers();
                // You can update your users list or perform any other necessary actions.
            } else {
                const data = await response.json();
                if (data && data.error) {
                this.error = data.error;
                } else {
                this.error = "Error deleting user.";
                }
            }
            } catch (error) {
            console.error(error);
            this.error = "Error deleting user.";
            }
        },
        openEditModal(user) {
            this.editingUserId = user.uid;
            this.editDisplayName = user.displayName;
            this.editEmail = user.email;
            this.editIsAdmin = user.isAdmin || false; // Set isAdmin based on user data
            this.showEditModal = true;
        },
        async submitEdit() {
            // Call your editUser method
            await this.editUser(
                this.editingUserId,
                this.editDisplayName,
                this.editEmail,
                this.editPassword,
                this.editIsAdmin
            );
            this.$refs["addTable-modal"].hide();
        },
    },
};
</script>
  
<style>
.error-message {
    color: red;
}

.edit-modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        padding: 20px;
        border: 1px solid #ccc;
        z-index: 1000;
    }
</style>
  