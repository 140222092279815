<template>
  <div style="text-align:left;padding:30px;display: flex;align-items: center;justify-content: center;height: 100vh;flex-direction: column;">
    <!-- Conditionally display login form or logout button -->
    <div v-if="!currentUser" class="card" style="width: 50%;">
      <h4>Logga in</h4>
      <b-form @submit.prevent="login">
        <label for="email">Email:</label>
        <b-form-input type="email" id="email" v-model="email" required />
        <br>
        <label for="password">Lösenord:</label>
        <b-form-input type="password" id="password" v-model="password" required />
        <br>
        <b-button class="btn btn-primary" type="submit">Logga in</b-button>
      </b-form>
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
    </div>
    <!-- Logout Button -->
    <div v-else class="logout-section card" style="width: 50%;">
      <h4>Du är inloggad</h4>
      <b-button class="btn btn-danger" @click="logout">Logga ut</b-button>
    </div>
    <div class="logos" style="margin-top:10px;">
      <img src="@/assets/walma-black.svg" alt="logo" style="width: 100px;padding:10px;" />
      <img src="../assets/dp-logo-brand.png" alt="logo" style="width: 220px;padding:10px;" />
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import 'firebase/auth';

export default {
  data() {
    return {
      email: '',
      password: '',
      errorMessage: null,
      currentUser: null, // Added to track user state
    };
  },
  created() {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        // Listen for auth state changes
        firebase.auth().onAuthStateChanged(user => {
          this.currentUser = user; // Update current user state
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  methods: {
    login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          window.location.reload();
        })
        .catch(error => {
          this.errorMessage = error.message;
          console.error(error);
        });
    },
    logout() {
      firebase.auth().signOut()
        .then(() => {
          window.location.reload();
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  margin-top: 5px;
}
.logout-section {
  text-align: center;
}
</style>
