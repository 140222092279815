// adminMixin.js
import firebase from '../firebase'; // Adjust the path to your firebase.js file

export default {
  data() {
    return {
      isAdminStatus: false,
    };
  },
  computed: {
    isAdmin() {
        return this.isAdminStatus;
      },
  },
  mounted() {
    firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          this.loggedInUserName = user.email;
  
          // Fetch the ID token and update admin status
          try {
            const idTokenResult = await user.getIdTokenResult();
            this.isAdminStatus = idTokenResult.claims.isAdmin === true;
          } catch (error) {
            console.error("Error fetching user token:", error);
            this.isAdminStatus = false;
          }
  
        } else {
          this.loggedInUserName = null;
          this.isAdminStatus = false; // Reset admin status on logout
        }
      });
  }
};
