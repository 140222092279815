<template>
    <div>
      <button @click="exportData">Exportera Delegationsordning</button>
    </div>
  </template>
  
  <script>
 import { db } from '../firebase.js';
  
  export default {
    methods: {
    async fetchDelegationsordning() {
      const snapshot = await db.collection('VersionDelegationsordning').get();
      const delegationsordning = [];

      snapshot.forEach(doc => {
        delegationsordning.push({ id: doc.id, ...doc.data() });
      });

      return delegationsordning;
    },
    downloadAsJson(data, filename = 'data.json') {
      const jsonStr = JSON.stringify(data, null, 2);
      const blob = new Blob([jsonStr], { type: 'application/json' });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url); // Rensa upp URL.createObjectURL
    },
    async exportData() {
      const data = await this.fetchDelegationsordning();
      this.downloadAsJson(data, 'VersionDelegationsordning.json');
    }
  }
}
  </script>