<template>
  <div v-if="loaded" style="text-align: left; padding: 30px">
    <b-button @click="toggleEditorMode" class="btn btn-primary edit-button">
      {{ editorMode ? "Sluta redigera" : "Redigera" }}
    </b-button>
    <div v-show="editorMode">
      <input type="text" v-model="newDocTitle" placeholder="Namn på delegationsordning" />
      <button @click="addNewDoc" class="btn btn-primary">
        Lägg till delegationsordning
      </button>
    </div>
    <div v-for="(newTable, orderIndex) in updatedTables" :key="newTable.id" class="card">
      <div v-show="editorMode">
        <button @click="deleteDocument(newTable.id)" class="btn btn-danger"
          style="width: 222px; position: absolute; right: 40px">
          Radera delegationsordning
        </button>
      </div>
      <h3>{{ newTable.title }}</h3>
      <div v-for="(row, tableIndex) in newTable.rows" :key="row.id" style="padding: 20px 10px">
        <h4 style="margin-bottom: 20px;" v-if="row.avsnitt !== ''">{{ row.avsnitt }}</h4>
        <h5>{{ row.id }}</h5>
        <form @submit.prevent="saveEdit">
          <table class="table table-striped table-bordered">
            <thead style="background: #5d0096; color: white">
              <tr>
                <th>Nr</th>
                <th>Ärendetyp</th>
                <th>Delegat</th>
                <th>v.d.</th>
                <th>Lagrum</th>
                <th>Anmärkning</th>
                <th>Ärenden som vidaredelegeras</th>
                <th>Vidaredelegat</th>
                <th v-show="editorMode">Ändra</th>
              </tr>
            </thead>
              <draggable v-model="row.table" tag="tbody">
                <tr v-for="(rowTable, rowIndex) in row.table" :key="rowTable.id">
                    <td v-if="editingRow == null">{{ rowTable.nr }}</td>
                    <td v-else><label><textarea class="form-control" v-model="editNr" /></label></td>
                    <td v-if="editingRow == null">{{ rowTable.arendetyp }}</td>
                    <td v-else><label><textarea class="form-control" v-model="rowTable.arendetyp" /></label></td>
                    <td v-if="editingRow == null">{{ rowTable.delegat }}</td>
                    <td v-else>
                      <select class="custom-select form-control" v-model="rowTable.delegat" :value="rowTable.delegat">
                        <option v-for="user in users" :key="user.key" :value="user.titel">
                          {{ user.titel }}
                        </option>
                      </select>
                    </td>
                    <td v-if="editingRow == null">{{ rowTable.vd }}</td>
                    <td v-else><label><textarea class="form-control" v-model="rowTable.vd" /></label></td>
                    <td v-if="editingRow == null">{{ rowTable.lagrum }}</td>
                    <td v-else><label><textarea class="form-control" v-model="rowTable.lagrum" /></label></td>
                    <td v-if="editingRow == null">{{ rowTable.anmarkning }}</td>
                    <td v-else><label><textarea class="form-control" v-model="rowTable.anmarkning" /></label></td>
                    <td v-if="editingRow == null">{{ rowTable.arendenVidare }}</td>
                    <td v-else><label><textarea class="form-control" v-model="rowTable.arendenVidare" /></label></td>
                    <td v-if="editingRow == null">{{ rowTable.vidaredelegat }}</td>
                    <td v-else>
                      <select class="custom-select form-control" v-model="rowTable.vidaredelegat" :value="rowTable.vidaredelegat">
                        <option v-for="user in users" :key="user.key" :value="user.titel">
                          {{ user.titel }}
                        </option>
                      </select>
                    </td>
                  <td v-show="editorMode">
                    <span>
                      <b-button v-b-modal.modal-1 @click="editRow(newTable.id, tableIndex, rowIndex)" class="btn btn-secondary"
                        style="margin: 0px 10px 0px 0px">Redigera</b-button>
                      <b-button @click="deleteRow(newTable.id, tableIndex, rowIndex)" class="btn btn-danger">Radera</b-button>
                    </span>
                    <span>
                      <button type="submit" class="btn btn-primary">Spara</button>
                      <button type="button" class="btn btn-secondary" @click="cancelEdit">
                        Avbryt
                      </button>
                    </span>
                  </td>
                </tr>
              </draggable>
          </table>
        </form>
        <div v-show="editorMode" style="float: right">
          <b-button v-b-modal.modal-2 @click="setCurrentTable(newTable.id, tableIndex)" class="btn btn-primary">Lägg till
            ny rad</b-button>
        </div>
      </div>
      <div>
        <div v-show="editorMode" style="float: left">
          <b-button v-b-modal.modal-3 @click="setCurrentTable(newTable.id, orderIndex)" class="btn btn-primary">
            Skapa ny tabell
          </b-button>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" size="xl" ref="editRow-modal" title="Redigera rad" hide-footer>
      <div v-if="editingRow !== null">
        <form @submit.prevent="saveEdit">
          <label>
            Nr:
            <textarea class="form-control" v-model="editNr" />
          </label>
          <label>
            Ärendetyp:
            <textarea class="form-control" v-model="editArendetyp" />
          </label>
          <label>
            Delegat:
            <select class="custom-select form-control" v-model="editDelegat" :value="editDelegat">
              <option v-for="user in users" :key="user.key" :value="user.titel">
                {{ user.titel }}
              </option>
            </select>
          </label>
          <label>
            v.d.:
            <textarea class="form-control" v-model="editVd" />
          </label>
          <label>
            Lagrum:
            <textarea class="form-control" v-model="editLagrum" />
          </label>
          <label>
            Anmärkning:
            <textarea class="form-control" v-model="editAnmarkning" />
          </label>
          <label>
            Ärenden som vidaredelegeras:
            <textarea class="form-control" v-model="editArendenVidare" />
          </label>
          <label>
            Vidaredelegat:
            <select class="custom-select form-control" v-model="editVidaredelegat" :value="editVidaredelegat">
              <option v-for="user in users" :key="user.key" :value="user.titel">
                {{ user.titel }}
              </option>
            </select>
          </label>
          <div class="custom-footer">
            <button type="submit" class="btn btn-primary">Spara</button>
            <button type="button" class="btn btn-secondary" @click="cancelEdit">
              Avbryt
            </button>
          </div>
        </form>
      </div>
    </b-modal>
    <b-modal id="modal-2" size="xl" ref="addRow-modal" title="Lägg till rad" hide-footer>
      <form inline @submit.prevent="addNewRow()" class="form-check form-check-inline">
        <label>
          Nr:
          <textarea class="form-control" :class="currentTableIndex" v-model="nr[currentTableId + currentTableIndex]" />
        </label>
        <label>
          Ärendetyp:
          <textarea class="form-control" :class="currentTableIndex"
            v-model="arendetyp[currentTableId + currentTableIndex]" />
        </label>
        <label>
          Delegat:
          <select class="custom-select form-control" v-model="delegat[currentTableId + currentTableIndex]">
            <option selected>Try</option>
            <option v-for="user in users" :key="user.key" :value="user.titel">
              {{ user.titel }}
            </option>
          </select>
        </label>
        <label>
          v.d.:
          <textarea class="form-control" :class="currentTableIndex" v-model="vd[currentTableId + currentTableIndex]" />
        </label>
        <label>
          Lagrum:
          <textarea class="form-control" :class="currentTableIndex"
            v-model="lagrum[currentTableId + currentTableIndex]" />
        </label>
        <label>
          Anmärkning:
          <textarea type="text" class="form-control" :class="currentTableIndex"
            v-model="anmarkning[currentTableId + currentTableIndex]" />
        </label>
        <label>
          Ärenden som vidaredelegareras:
          <textarea type="text" class="form-control" :class="currentTableIndex"
            v-model="arendenVidare[currentTableId + currentTableIndex]" />
        </label>
        <label>
          Vidaredelegat:
          <textarea type="text" class="form-control" :class="currentTableIndex"
            v-model="vidaredelegat[currentTableId + currentTableIndex]" />
        </label>
        <div class="custom-footer">
          <button type="submit" class="btn btn-primary">Lägg till rad</button>
        </div>
      </form>
    </b-modal>
    <b-modal id="modal-3" size="xl" ref="addTable-modal" title="Lägg till tabell" hide-footer>
          <form inline @submit.prevent="addNewTable(currentTableId, currentTableIndex)">
            <label>
              Avsnitt(tomt om redan befintligt):
              <textarea class="form-control" v-model="avsnitt[currentTableIndex + currentTableId]" />
            </label>
            <label>
              Titel:
              <textarea class="form-control" v-model="title[currentTableIndex + currentTableId]" />
            </label>
            <label>
              Nr:
              <textarea class="form-control" v-model="nr[currentTableIndex + currentTableId]" />
            </label>
            <label>
              Ärendetyp:
              <textarea class="form-control" v-model="arendetyp[currentTableIndex + currentTableId]" />
            </label>
            <label>
              Delegat:
              <select class="custom-select form-control" v-model="delegat[currentTableIndex + currentTableId]">
                <option selected>Try</option>
                <option v-for="user in users" :key="user.key" :value="user.titel">
                  {{ user.titel }}
                </option>
              </select>
            </label>
            <label>
              v.d.:
              <textarea class="form-control" v-model="vd[currentTableIndex + currentTableId]" />
            </label>
            <label>
              Lagrum:
              <textarea class="form-control" v-model="lagrum[currentTableIndex + currentTableId]" />
            </label>
            <label>
              Anmärkning:
              <textarea class="form-control" v-model="anmarkning[currentTableIndex + currentTableId]" />
            </label>
            <label>
              Ärenden som vidaredelegeras:
              <textarea class="form-control" v-model="arendenVidare[currentTableIndex + currentTableId]" />
            </label>
            <label>
              Vidaredelegat:
              <textarea class="form-control" v-model="vidaredelegat[currentTableIndex + currentTableId]" />
            </label>
            <button type="submit" class="btn btn-primary">Lägg till tabell</button>
          </form>
      </b-modal>
</div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import draggable from 'vuedraggable';

const Delegationsportalen = {
  apiKey: "AIzaSyCNQGyNShyUTbCWFPDFfxIdn9Q6b0SQMf8",
  authDomain: "delegationsportalen.firebaseapp.com",
  projectId: "delegationsportalen",
  storageBucket: "delegationsportalen.appspot.com",
  messagingSenderId: "537346964631",
  appId: "1:537346964631:web:1e864405c638f01bcd7dd2"
};

firebase.initializeApp(Delegationsportalen);

const db = firebase.firestore();

export default {
  components: {
    draggable
  },
  data() {
    return {
      loaded: false,
      editorMode: false,
      tables: [],
      updatedTables: [],
      users: [],
      currentTableId: "",
      currentTableIndex: "",
      newDocTitle: "",
      newRows: [],
      avsnitt: [],
      title: [],
      nr: [],
      arendetyp: [],
      delegat: [],
      vd: [],
      lagrum: [],
      anmarkning: [],
      arendenVidare: [],
      vidaredelegat: [],
      editingRow: null,
      editId: null,
      editNr: null,
      editArendetyp: null,
      editDelegat: null,
      editVd: null,
      editLagrum: null,
      editAnmarkning: null,
      editArendenVidare: null,
      editVidaredelegat: null,
    };
  },
  mounted() {
    // Fetch initial user list on component mount
    this.fetchUsers();
    this.reloadTable();
  },
  methods: {
    reloadTable() {
      db.collection("Delegationsordning")
        .orderBy("timestamp", "asc")
        .onSnapshot((snapshot) => {
          this.updatedTables.splice(0); // clear the array
          this.tables = snapshot.docs.map((doc) => {
            const tableData = doc.data();
            tableData.id = doc.id; // add document ID to table data
            //console.log(tableData.id);
            this.updatedTables.push(tableData);
          });
          this.loaded = true;
        });
    },
    toggleEditorMode() {
      this.editorMode = !this.editorMode;
    },
    addNewDoc() {
      console.log("addDoc function called");
      db.collection("Delegationsordning")
        .add({
          id: Math.random().toString(36).substr(2, 9),
          title: this.newDocTitle,
          rows: [],
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          this.newDocTitle = "";
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    setCurrentTable(tableId, tableIndex) {
      this.currentTableId = tableId;
      this.currentTableIndex = tableIndex;
    },
    updateTable() {
      db.collection("Delegationsordning")
        .doc(this.editedTable.id)
        .update(this.editedTable);
      this.editing = false;
      this.editedTable = null;
    },
    addNewTable(tableId, index) {
      this.$refs["addTable-modal"].hide();
      const foundEntry = this.updatedTables.find((entry) => {
        return entry.id === tableId;
      });

      const newRow = {
        avsnitt: this.avsnitt[index + tableId] ? this.avsnitt[index + tableId] : "",
        id: this.title[index + tableId] ? this.title[index + tableId] : "",
        table: [
          {
            id: Math.random().toString(36).substr(2, 9),
            nr: this.nr[index + tableId] ? this.nr[index + tableId] : "",
            arendetyp: this.arendetyp[index + tableId] ? this.arendetyp[index + tableId] : "",
            delegat: this.delegat[index + tableId] ? this.delegat[index + tableId] : "",
            vd: this.vd[index + tableId] ? this.vd[index + tableId] : "",
            lagrum: this.lagrum[index + tableId] ? this.lagrum[index + tableId] : "",
            anmarkning: this.anmarkning[index + tableId] ? this.anmarkning[index + tableId] : "",
            arendenVidare: this.arendenVidare[index + tableId] ? this.arendenVidare[index + tableId] : "",
            vidaredelegat: this.vidaredelegat[index + tableId] ? this.vidaredelegat[index + tableId] : "",
          },
        ],
      };
      if (Object.keys(foundEntry.rows).length === 1) {
        this.newRows.push(foundEntry.rows[0]);
      } else {
        for (let i = 0; i < Object.keys(foundEntry.rows).length; i++) {
          this.newRows.push(foundEntry.rows[i]);
        }
      }
      this.newRows.push(newRow);
      console.log(this.newRows);

      db.collection("Delegationsordning").doc(tableId).update({
        rows: this.newRows,
      });

      this.newRows = [];
      this.title = [];
      this.delegat = [];
      this.nr = [];
      this.arendetyp = [];
      this.vd = [];
      this.lagrum = [];
      this.anmarkning = [];
      this.arendenVidare = [];
      this.vidaredelegat = [];
      this.tables = [];
      this.updatedTables = [];
    },
    addNewRow() {
      this.$refs["addRow-modal"].hide();
      const foundEntry = this.updatedTables.find((entry) => {
        return entry.id === this.currentTableId;
      });

      const rowId = Math.random().toString(36).substr(2, 9);

      const newRow = {
        id: rowId,
        nr: this.nr[this.currentTableId + this.currentTableIndex] ? this.nr[this.currentTableId + this.currentTableIndex] : "",
        delegat: this.delegat[this.currentTableId + this.currentTableIndex] ? this.delegat[this.currentTableId + this.currentTableIndex] : "",
        arendetyp: this.arendetyp[this.currentTableId + this.currentTableIndex] ? this.arendetyp[this.currentTableId + this.currentTableIndex] : "",
        vd: this.vd[this.currentTableId + this.currentTableIndex] ? this.vd[this.currentTableId + this.currentTableIndex] : "",
        lagrum: this.lagrum[this.currentTableId + this.currentTableIndex] ? this.lagrum[this.currentTableId + this.currentTableIndex] : "",
        anmarkning: this.anmarkning[this.currentTableId + this.currentTableIndex] ? this.anmarkning[this.currentTableId + this.currentTableIndex] : "",
        arendenVidare: this.arendenVidare[this.currentTableId + this.currentTableIndex] ? this.arendenVidare[this.currentTableId + this.currentTableIndex] : "",
        vidaredelegat: this.vidaredelegat[this.currentTableId + this.currentTableIndex] ? this.vidaredelegat[this.currentTableId + this.currentTableIndex] : "",
      };

      // Copy existing rows into newRows
      if (foundEntry.rows[this.currentTableIndex].table.length > 0) {
        for (
          let i = 0;
          i < foundEntry.rows[this.currentTableIndex].table.length;
          i++
        ) {
          this.newRows.push(foundEntry.rows[this.currentTableIndex].table[i]);
        }
      }

      const docRef = db
        .collection("Delegationsordning")
        .doc(this.currentTableId);
      docRef.get().then((doc) => {
        if (doc.exists) {
          const rows = doc.data().rows;
          rows[this.currentTableIndex].table = this.newRows;
          console.log(rows);
          console.log(rows[this.currentTableIndex].table);
          docRef
            .update({ rows })
            .then(() => {
              // Clear newRows after update is successful
              this.newRows = [];
              this.currentTableIndex = "";
              this.currentTableId = "";
            })
            .catch((error) => {
              console.error("Error updating document: ", error);
            });
        } else {
          console.log("Document not found");
        }
      });
      // Add the new row
      this.newRows.push(newRow);
    },
    editRow(tableId, tableIndex, rowIndex) {
      this.editingRow = {
        tableId,
        tableIndex,
        rowIndex,
      };

      const docRef = db.collection("Delegationsordning").doc(tableId);
      docRef.get().then((doc) => {
        if (doc.exists) {
          const rows = doc.data().rows;
          console.log(rows[tableIndex].table[rowIndex]);
          this.editId = rows[tableIndex].table[rowIndex].id ? rows[tableIndex].table[rowIndex].id : "";
          this.editDelegat = rows[tableIndex].table[rowIndex].delegat ? rows[tableIndex].table[rowIndex].delegat : "";
          this.editNr = rows[tableIndex].table[rowIndex].nr ? rows[tableIndex].table[rowIndex].nr : "";
          this.editArendetyp = rows[tableIndex].table[rowIndex].arendetyp ? rows[tableIndex].table[rowIndex].arendetyp : "";
          this.editVd = rows[tableIndex].table[rowIndex].vd ? rows[tableIndex].table[rowIndex].vd : "";
          this.editLagrum = rows[tableIndex].table[rowIndex].lagrum ? rows[tableIndex].table[rowIndex].lagrum : "";
          this.editAnmarkning = rows[tableIndex].table[rowIndex].anmarkning ? rows[tableIndex].table[rowIndex].anmarkning : "";
          this.editArendenVidare = rows[tableIndex].table[rowIndex].arendenVidare ? rows[tableIndex].table[rowIndex].arendenVidare : "";
          this.editVidaredelegat = rows[tableIndex].table[rowIndex].vidaredelegat ? rows[tableIndex].table[rowIndex].vidaredelegat : "";
        } else {
          console.log("Document not found");
        }
      });
    },
    cancelEdit() {
      this.editingRow = null;
      this.$refs["editRow-modal"].hide();
    },
    saveEdit() {
      this.$refs["editRow-modal"].hide();
      const { tableId, tableIndex, rowIndex } = this.editingRow;
      console.log(tableId, tableIndex, rowIndex, this.editingRow);

      const editRow = {
        id: this.editId,
        delegat: this.editDelegat,
        nr: this.editNr,
        arendetyp: this.editArendetyp,
        vd: this.editVd,
        lagrum: this.editLagrum,
        anmarkning: this.editAnmarkning,
        arendenVidare: this.editArendenVidare,
        vidaredelegat: this.editVidaredelegat,
      };

      const docRef = db.collection("Delegationsordning").doc(tableId);
      docRef.get().then((doc) => {
        if (doc.exists) {
          const rows = doc.data().rows;
          rows[tableIndex].table[rowIndex] = editRow;
          docRef
            .update({ rows })
            .then(() => {
              console.log("Row updated successfully!");
              this.cancelEdit();
            })
            .catch((error) => {
              console.error("Error updating document: ", error);
            });
        } else {
          console.log("Document not found");
        }
      });
    },
    deleteDocument(docId) {
      if (confirm("Vill du verkligen ta bort denna delegationsordning?")) {
        db.collection("Delegationsordning")
          .doc(docId)
          .delete()
          .then(() => {
            console.log("Document successfully deleted!");
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
      }
    },
    deleteRow(tableId, tableIndex, rowIndex) {
      if (confirm("Vill du verkligen radera denna rad?")) {
        const docRef = db.collection("Delegationsordning").doc(tableId);
        docRef.get().then((doc) => {
          if (doc.exists) {
            const rows = doc.data().rows;
            rows[tableIndex].table.splice(rowIndex, 1); // remove row from the rows array
            docRef
              .update({ rows })
              .then(() => {
                console.log("Row updated successfully!");
                this.cancelEdit();
              })
              .catch((error) => {
                console.error("Error updating document: ", error);
              });
          } else {
            console.log("Document not found");
          }
        });
      }
    },
    fetchUsers() {
      // Fetch all users from Firestore collection "Delegater"
      db.collection("Delegater")
        .get()
        .then((querySnapshot) => {
          const users = [];
          querySnapshot.forEach((doc) => {
            users.push(doc.data());
          });
          this.users = users;
        })
        .catch((error) => {
          console.error("Error fetching users: ", error);
        });
    },
  },
};
</script>
<style scoped>
td {
 cursor: grab;
}

.edit-button {
  position: fixed;
    z-index: +1;
    bottom: 20px;
    right: 20px;
    background: #b44595!important;
    border: 0px;
}

table .form-control {
    min-width: 150px;
    min-height: 150px;
}

table td:first-child .form-control, table td:nth-child(5) .form-control {
    min-width: 50px;
}

table td:nth-child(4) .form-control {
    min-width: 50px;
}

table td:nth-child(2) .form-control, table td:nth-child(6) .form-control {
    min-width: 210px;
}

table td:nth-child(3) .form-control, table td:nth-child(8) .form-control {
    min-width: 180px;
    min-height: auto;
}

table td, table .form-control {
    font-size: 14px
}

</style>
