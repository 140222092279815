<template>
  <b-container fluid>
    <span
      class="views"
      style="color: #342768 !important; background: #c2b9e5 !important"
      v-if="!isAdmin"
      >Intern</span
    >
    <span
      class="views"
      style="color: #342768; background: #e8edff"
      v-if="isAdmin"
      >Admin</span
    >
    <!-- Navbar for Mobile and Header for Desktop -->
    <!-- Navbar -->
    <b-navbar
      toggleable="lg"
      class="navbar-dark bg-darker main-view"
      style="justify-content: space-between; padding: 10px; margin-top: 32.5px"
    >
      <b-navbar-toggle @click="toggleSidebar" aria-label="Öppna/stäng navigation" target="target"></b-navbar-toggle>
      <!--<b-navbar-brand href="#"></b-navbar-brand>-->
      <div></div>
      <!-- Logout Button (only visible when logged in) -->
      <div v-if="isAdmin">
        <b-button
          @click="signOut"
          class="ml-auto"
          variant="outline-danger"
          style="margin-right: 10px"
        >
          Logga ut
        </b-button>
        <router-link class="btn btn-primary" :to="`/admin/`"
          >Adminpanel</router-link
        >
      </div>
    </b-navbar>

    <!-- Body: Sidebar and Main Content -->
    <div>
      <!-- Sidebar -->
      <transition name="slide">
        <div class="sidebar bg-darker" v-show="isSidebarVisible">
          <div class="logo">
            <router-link :to="`/`"><img
              src="@/assets/walma.svg"
              alt="Logga för Walma"
            /></router-link>
          </div>
          <!-- Sidebar Navigation -->
          <b-nav vertical class="mt-3" aria-label="Sidomeny Navigation">
            <ul v-if="isAdmin" class="sidebar-list">
              <li><router-link
                :to="`/`"
                @click.native="toggleSidebarIfMobile"
                ><i class="bi bi-house" style="margin-right: 10px"></i>Hem</router-link
              ></li>
              <li><a href="#" v-b-toggle.collapse-2>
                <i class="bi bi-book" style="margin-right: 10px"></i>Delegationsordningar
                <span class="when-opened">
                  <i class="bi bi-chevron-down"></i>
                </span>
                <span class="when-closed">
                  <i class="bi bi-chevron-right"></i>
                </span>
              </a>
              <b-collapse v-if="linkTables" class="submenu" id="collapse-2">
                <router-link
                  v-if="isAdmin"
                  :to="`/lagg-till/`"
                  style="
                    padding: 5px;
                    border-radius: 10px;
                    border: 3px dotted #e7f1f3;
                  "
                  @click.native="toggleSidebarIfMobile"
                  ><i
                    class="bi bi-plus-square-dotted"
                    style="margin-right: 10px"
                  ></i
                  >Ny delegationsordning</router-link
                >
                <router-link
                  :to="`/delegationsordningar/${linkTable.id}`"
                  v-for="linkTable in linkTables"
                  :key="linkTable.id"
                  @click.native="toggleSidebarIfMobile"
                  >{{ linkTable.title
                  }}<i
                    class="bi bi-trash"
                    @click="deleteDelegationsordning(linkTable.id)"
                    style="display: none; margin-left: 10px"
                  ></i>
                </router-link>
              </b-collapse>
            </li>
              <li v-if="isAdmin"><router-link
                :to="`/delegater`"
                @click.native="toggleSidebarIfMobile"
                ><i class="bi bi-people" style="margin-right: 10px"></i
                >Delegater</router-link
              ></li>
              <li><router-link
                :to="`/andringar`"
                v-if="isAdmin"
                @click.native="toggleSidebarIfMobile"
                ><i class="bi bi-pencil-square" style="margin-right: 10px"></i
                >Ändringar
                <span class="changesBubble">{{
                  this.pendingUpdates.length
                }}</span></router-link
              ></li>
              <li><router-link
                :to="`/anmal-andring`"
                @click.native="toggleSidebarIfMobile"
                ><i class="bi bi-flag" style="margin-right: 10px"></i>Anmälda ändringar
                <span class="changesBubble" v-if="isAdmin">{{
                  this.reportedChanges.length
                }}</span></router-link
              ></li>
              </ul>
            <ul class="sidebar-list" v-else>
              <li><router-link
                :to="`/`"
                @click.native="toggleSidebarIfMobile"
                ><i class="bi bi-house" style="margin-right: 10px"></i>Hem</router-link
              ></li>
              <li>
                <a href="#" v-b-toggle.collapse-2>
                <i class="bi bi-book" style="margin-right: 10px"></i>Delegationsordningar
                <span class="when-opened">
                  <i class="bi bi-chevron-down"></i>
                </span>
                <span class="when-closed">
                  <i class="bi bi-chevron-right"></i>
                </span>
              </a>
              <b-collapse
                v-if="linkTables"
                class="submenu"
                id="collapse-2"
                @click.native="toggleSidebarIfMobile"
              >
                <router-link
                  :to="`/delegationsordningar/${linkTable.id}`"
                  v-for="linkTable in linkTables"
                  :key="linkTable.id"
                  >{{ linkTable.title }}</router-link
                >
              </b-collapse></li>
              <li><router-link :to="`/delegater`">
                <i
                  class="bi bi-people"
                  style="margin-right: 10px"
                  @click.native="toggleSidebarIfMobile"
                ></i
                >Delegater</router-link
              ></li>
              <li><router-link
                :to="`/anmal-andring`"
                @click.native="toggleSidebarIfMobile"
                ><i class="bi bi-flag" style="margin-right: 10px"></i>Anmäl
                ändring
                <span class="changesBubble" v-if="isAdmin">
                {{ this.reportedChanges.length }}</span></router-link
              ></li>
              <li><router-link :to="`/login`"
                ><i
                  class="bi bi-box-arrow-in-right"
                  style="margin-right: 10px"
                  @click.native="toggleSidebarIfMobile"
                ></i
                >Logga in</router-link
              ></li>
              </ul>
            <div class="sidebar-footer" style="display: none">
              <div class="sidebar-footer-item">
                <img src="./assets/corner.png" alt="plus" />
              </div>
            </div>
            <!-- Add more links here -->
          </b-nav>
        </div>
      </transition>

      <!-- Main Content -->
      <b-col lg="10" class="main-view">
        <!-- Your main content goes here -->
        <router-view />
      </b-col>
    </div>
  </b-container>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
//import SearchFunction from "./components/SearchFunction.vue";

const Delegationsportalen = {
  apiKey: "AIzaSyCNQGyNShyUTbCWFPDFfxIdn9Q6b0SQMf8",
  authDomain: "delegationsportalen.firebaseapp.com",
  projectId: "delegationsportalen",
  storageBucket: "delegationsportalen.appspot.com",
  messagingSenderId: "537346964631",
  appId: "1:537346964631:web:1e864405c638f01bcd7dd2"
};

firebase.initializeApp(Delegationsportalen);

const db = firebase.firestore();

export default {
  name: "App",
  components: {
    //SearchFunction,
  },
  data() {
    return {
      linkTables: [],
      sidebarShow: false, // Controls the visibility of the sidebar
      pendingUpdates: [],
      reportedChanges: [],
      loggedInUserName: null, // Store logged-in user's profile name
      isAdminStatus: false, // Add this line
    };
  },
  mounted() {
    this.updateSidebarVisibility(); // Existing code
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.loggedInUserName = user.email;

        // Fetch the ID token and update admin status
        try {
          const idTokenResult = await user.getIdTokenResult();
          this.isAdminStatus = idTokenResult.claims.isAdmin === true;
          this.$store.dispatch('updateAdminStatus', this.isAdminStatus); // Använd Vuex store för att uppdatera adminstatus
        } catch (error) {
          console.error("Error fetching user token:", error);
          this.isAdminStatus = false;
          this.$store.dispatch('updateAdminStatus', false); // Återställ adminstatus vid fel
        }
      } else {
        this.loggedInUserName = null;
        this.isAdminStatus = false; // Reset admin status on logout
        this.$store.dispatch('updateAdminStatus', false); // Återställ adminstatus vid utloggning
      }


      if (user) {
          db.collection("PendingDelegationsordning")
          .orderBy("title", "asc")
          .onSnapshot((snapshot) => {
            this.linkTables.splice(0); // clear the array
            this.tables = snapshot.docs.map((doc) => {
              const tableData = doc.data();
              tableData.id = doc.id; // add document ID to table data
              //console.log(tableData.id);
              this.linkTables.push(tableData);
            });
            this.loaded = true;
            console.log(this.linkTables);
          });
        } else {
          db.collection("Delegationsordning")
          .orderBy("title", "asc")
          .onSnapshot((snapshot) => {
            this.linkTables.splice(0); // clear the array
            this.tables = snapshot.docs.map((doc) => {
              const tableData = doc.data();
              tableData.id = doc.id; // add document ID to table data
              //console.log(tableData.id);
              this.linkTables.push(tableData);
            });
            this.loaded = true;
            console.log(this.linkTables);
          });
        }
    });

    db.collection("TempDelegationsordning").onSnapshot((snapshot) => {
      this.pendingUpdates.splice(0); // clear the array
      this.tables = snapshot.docs.map((doc) => {
        const tableData = doc.data();
        tableData.id = doc.id; // add document ID to table data
        //console.log(tableData.id);
        this.pendingUpdates.push(tableData);
      });
      this.loaded = true;
      console.log(this.pendingUpdates);
    });

    db.collection("Reported").onSnapshot((snapshot) => {
      this.reportedChanges.splice(0); // clear the array
      this.tables = snapshot.docs.map((doc) => {
        const tableData = doc.data();
        tableData.id = doc.id; // add document ID to table data
        //console.log(tableData.id);
        this.reportedChanges.push(tableData);
      });
      this.loaded = true;
      console.log(this.reportedChanges);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    toggleSidebar() {
      this.sidebarShow = !this.sidebarShow;
      this.updateSidebarVisibility();
    },
    toggleSidebarIfMobile() {
      if (window.innerWidth <= 992) {
        this.toggleSidebar();
      }
    },
    updateSidebarVisibility() {
      const sidebar = document.querySelector(".sidebar");
      if (this.sidebarShow) {
        sidebar.classList.add("sidebar-show");
      } else {
        sidebar.classList.remove("sidebar-show");
      }
    },
    handleResize() {
      this.sidebarShow = window.innerWidth > 992;
    },
    async signOut() {
      try {
        await firebase.auth().signOut(); // Sign out using Firebase auth
        console.log("Sign out successful");

        // Redirect to the login page and then reload the page
        window.location.reload();
      } catch (error) {
        console.error("Error signing out", error);
      }
    },
    deleteDelegationsordning(id) {
      if (
        confirm(
          "Är du säker på att du vill radera den här delegationsordningen?"
        )
      ) {
        db.collection("PendingDelegationsordning")
          .doc(id)
          .delete()
          .then(() => {
            console.log("Delegationsordningen har raderats");
          })
          .catch((error) => {
            console.error("Fel vid radering av delegationsordningen: ", error);
          });
      }
    },
  },
  computed: {
    isSidebarVisible() {
      // Always show the sidebar on large screens
      return window.innerWidth > 992 || this.sidebarShow;
    },
    isAdmin() {
      // Now depends on the data property 'isAdminStatus'
      return this.isAdminStatus;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

* {
  transition: all 0.3s ease-in-out;
}

@media print {
  .no-print {
    visibility: hidden;
  }
  .main {
    width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
  }
  * {
    font-size: 1pt !important;
  }
  button {
    display: none !important;
  }
}

body {
  background-color: #f6f9fc !important;
}

a {
  color: #342768;
}

h3 {
  color: #342768;
  padding-bottom: 15px;
  font-size: 1.4rem !important;
}

h4 {
  font-size: 1.2rem !important;
}

h5 {
  font-size: 1rem !important;
}

a:hover {
  opacity: 0.6;
}

.sidebar-list a:hover {
  color: rgba(255, 255, 255, 0.5) !important;
  opacity: 1;
}

thead {
  background: #342768 !important;
  color: white !important;
}

.main-view.col-lg-10 {
  width: auto !important;
}

.modal.show .modal-dialog {
  margin-top: 60px;
}

.router-link-exact-active {
  font-weight: bold;
  background: #342768;
  border-radius: 10px;
}

.card {
  padding: 15px;
  background: white;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
  border-radius: 10px;
  margin-bottom: 20px;
  border: 0px;
  font-size: 15px;
  border: 0px !important;
}

.btn {
  font-size: 13px !important;
  font-weight: 500 !important;
  border-width: 0px !important;
}

.btn-primary {
  background: #342768 !important;
}

.btn-info {
  background: #e1e5fb !important;
  color: #4458e3 !important;
}

.btn-cancel {
  background: #e1e5fb !important;
  color: #4458e3 !important;
}

.btn-danger {
  background: #fbe1e2 !important;
  color: #e34448 !important;
}

.logo {
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 75px;
}

.logo img {
  height: 100%;
  max-width: 200px;
}

.logo a {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent!important;
}

.navbar button,
.navbar a {
  color: white !important;
}

/* Fixed Sidebar Styling */
.sidebar {
  position: fixed; /* Fixed positioning */
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh; /* Full height */
  overflow-y: auto; /* Scroll for overflow content */
  width: 300px; /* Fixed width */
  transform: translateX(0); /* Initially visible */
  transition: transform 0.3s ease-in-out;
}

/* Adjust main content area */
.main-view {
  margin-left: 300px; /* Adjust margin to account for sidebar width */
  transition: margin-left 0.3s ease-in-out;
}

.sidebar-list {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 15px;
  font-size: 14px;
  list-style-type: none;
}

.sidebar-list li{
  padding: 7.5px 0px;
}

.submenu {
  font-size: 14px;
  padding-left: 15px;
}

.submenu a {
  float: left;
}

.sidebar {
  height: 100vh;
}

.sidebar-list a {
  margin: 5px 0px;
  text-decoration: none;
  color: white;
  padding: 5px 10px;
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
}

.sidebar-footer-item img {
  width: 100%;
  margin-bottom: -30px;
}

.bg-darker {
  background-color: #1f2937 !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 0px !important;
}

/* Transition for sidebar visibility */
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}

@media (max-width: 768px) {
  /* Adjust this value based on your breakpoint */
  .sidebar {
    width: 80%; /* Smaller width on smaller screens */
    transform: translateX(-100%); /* Initially off-screen */
    top: 92px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  .main-view {
    margin-left: 0; /* No margin when sidebar is hidden */
  }
  .sidebar-show {
    transform: translateX(0); /* Show sidebar on toggle */
  }
}

.hidden {
  display: none;
}

.profile {
  font-size: 14px;
}

.changesBubble {
  font-size: 12px;
  background: #342768;
  color: white;
  border-radius: 50%;
  padding: 3px 6px;
  font-weight: 600;
}

.views {
  display: block;
  width: 100%;
  padding: 5px 5px;
  color: white !important;
  background: #342768 !important;
  font-weight: bold;
  position: fixed;
  z-index: 99999;
  top: 0;
}

.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.nav-tabs .nav-link {
  color: #342768 !important;
}

.message {
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  background: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 9999;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, top 0.2s ease-in-out;
}

.is-visible {
  opacity: 1;
  top: 15px;
}
/*
.sidebar {
  background: white;
  box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
  width: 260px;
  height: 100vh;
  float: left;
  position: fixed;
  left: 0;
  top: 0;
}

.logo {
  background: #e7f1f3;
  height: 75px;
  display:flex;
}

.logo img {
  height: 100%;
  padding: 15px 0px 15px 15px;
}

.sidebar-list {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 15px;
  font-size: 14px;
}

.submenu {
  font-size: 14px;
  padding-left: 15px;
}

.submenu a {
  float: left;
}

.sidebar-list a {
  margin: 5px 0px;
  text-decoration: none;
  color: #342768;
  padding: 5px 10px;
}

.sidebar-footer {
  position: absolute;
  bottom: 0;
}

.sidebar-footer-item img {
  width: 100%;
  margin-bottom: -30px;
}

.main {
  width: calc(100% - 260px);
  float: right;
}

.modal-content textarea {
  min-height: 200px !important;
}

.modal-content label {
  margin-right: 20px;
}


.changesBubble {
  font-size: 12px;
  background: #1d627d;
  color: white;
  border-radius: 50%;
  padding: 3px 6px;
  font-weight: 600;
}

.header {
    background: white;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 10%);
    padding:15px;
    display:flex;
    justify-content: space-between;
    align-items:center;
}

.header .search-container {
    font-size:14px;
    width: 30%;
    clear:both;
} 
*/
</style>
