<template>
    <div v-if="message" class="message" :class="{ 'is-visible': isVisible }">
      {{ message }}
    </div>
  </template>
  
  <script>
  export default {
    props: {
      message: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        isVisible: false,
      };
    },
    watch: {
      message(newMessage) {
        if (newMessage) {
          this.isVisible = true;
          setTimeout(() => {
            this.isVisible = false;
          }, 3000); // hide after 5 seconds
        }
      },
    },
    mounted() {
    // set isVisible to true on component initialization to trigger the CSS transition
        this.isVisible = true;
    },
  };
  </script>
  